html,
body {
  height: 100%;
  margin: 0;
  font-family: VodafoneRg, sans-serif;
  touch-action: manipulation;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  overscroll-behavior-y: none;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0); /* For older iOS versions */
}

/* html {
  height: 100%;
  /* overflow: hidden;
} */
/* 

::-webkit-scrollbar {
  display: none;
} */
